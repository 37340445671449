/**
 * BitNinja Reseller Panel Main module*
 *
 * @module main
 */

$(function () {
   // $('[data-toggle="tooltip"]').tooltip();
});

function sendDeleteMessage(element) {
    
    $.ajax({
        type: 'POST',
        url: '/preferences/sendEmailAboutUserDelete',
        beforeSend: function() {
            $('.gdpr').prop( "disabled", true );
            $('#gdprmodal').hide();
            $('.modal-backdrop.fade.in').hide();
            $.ui.stripeLoadIndicator.show();
        },
        success: function () {
            $.ui.flashMsg({
                'text':'Your account will be deleted with all your data within 5 business days.',
                'class': 'success'
            });
        },
        error: function () {
            $(element).prop( "disabled", false );
                    $.ui.flashMsg({
                        'text': 'Your request is unsuccessful. Please try again later!',
                        'class': 'error'
                    });
        },
        complete: function () {
            $.ui.stripeLoadIndicator.hide();
        }
    });
}


function sendTestNotifications(element) {
    
    $.ajax({
        type: 'POST',
        url: '/preferences/sendTestNotifications',
        error: function () {
            $(element).prop( "disabled", false );
                    $.ui.flashMsg({
                        'text': 'Your request is unsuccessful. Please try again later!',
                        'class': 'error'
                    });
        },
        complete: function () {
            $.ui.stripeLoadIndicator.hide();
        }
    });
}
